import React from 'react'

import { useLocation, Link } from 'react-router-dom'

import {
  Box,
  Grid,
  Typography,
  Paper,
  Stack,
  Button
}
  from '@mui/material'

import {
  ThemeProvider,
  createTheme,
  PaletteColorOptions,
  PaletteColor
} from "@mui/material/styles";

declare module "@mui/material/styles" {
  // allow configuration using `createTheme`
  interface PaletteOptions {
    orange?: PaletteColorOptions;
  }

  interface Palette {
    orange: PaletteColor;
  }

  interface ButtonPropsColorOverrides {
    orange: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    orange: true;
  }
}

const theme = createTheme({
  palette: {
    orange: {
      main: "#EAA628"
    }
  }
});

export default function ThankYou() {

  return (
    <ThemeProvider theme={theme}>

      {/* BACKGROUND DIV */}
      <div className="card" style={{
        background: "linear-gradient(90deg, #286A8A, #76EA89)", backgroundRepeat: 'no-repeat',
        backgroundSize: "cover", backgroundPosition: "center",
        width: '100vw', height: '100vh'
      }}>

        <Stack>
          <Typography variant="h3"
            sx={{
              textAlign: "center", color: 'white', pt: 8, pb: 4
            }}>
            AWS Builders Quest
          </Typography>
          <Typography variant="h5"
            sx={{
              textAlign: "center", color: 'white', pt: 2, pb: 6
            }}>
            Thank you for your submission - you will receive an email from Credly shortly.
          </Typography>
          <Grid container justifyContent='center' alignItems='center'>
            <Link style={{ textDecoration: 'none' }} to={`/`}>
              <Button variant="contained" color="orange" sx={{ color: 'white' }}>
                I'm Done!
              </Button>
            </Link>
          </Grid>
        </Stack>
      </div>
    </ThemeProvider>
  );
}