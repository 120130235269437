import { useState, useContext } from 'react'
import '../../App.css'

import { useNavigate, useLocation, useParams } from 'react-router-dom'

import {
  ThemeProvider,
  createTheme,
  PaletteColorOptions,
  PaletteColor
} from "@mui/material/styles";

import {
  Box,
  Grid,
  Button,
  Typography,
  Paper,
  Stack,
  Container
}
  from '@mui/material'

import { useValidPassword, useValidUsername, useValidEmail, useValidCode } from '../../hooks/useAuthHooks'
import { Password, Username, Email, Code } from '../../components/authComponents'

import { AuthContext } from '../../contexts/authContext'

declare module "@mui/material/styles" {
  // allow configuration using `createTheme`
  interface PaletteOptions {
    orange?: PaletteColorOptions;
  }

  interface Palette {
    orange: PaletteColor;
  }

  interface ButtonPropsColorOverrides {
    orange: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    orange: true;
  }
}

const theme = createTheme({
  palette: {
    orange: {
      main: "##FEAB34"
    }
  }
});

export default function SignUp() {

  const theme = createTheme({
    palette: {
      orange: {
        main: '#EAA628'
      }
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            input: {
              "&:-webkit-autofill": {
                WebkitBoxShadow: "0 0 0 100px #3282ab inset",
                WebkitTextFillColor: "white",
              },
            },
          },
        },
      },
    },
  });

  const queryParameters = new URLSearchParams(window.location.search)
  const eventId = queryParameters.get("Event")

  const { email, setEmail, emailIsValid } = useValidEmail('')
  const { password, setPassword, passwordIsValid } = useValidPassword('')
  const { username, setUsername, usernameIsValid } = useValidUsername('')
  const { code, setCode, codeIsValid } = useValidCode('')
  const [error, setError] = useState('')
  const [created, setCreated] = useState(false)

  const location = useLocation();

  const {
    password: passwordConfirm,
    setPassword: setPasswordConfirm,
    passwordIsValid: passwordConfirmIsValid,
  } = useValidPassword('')

  const isValid =
    !emailIsValid ||
    email.length === 0 ||
    // !usernameIsValid ||
    // username.length === 0 ||
    !passwordIsValid ||
    password.length === 0 ||
    !passwordConfirmIsValid ||
    passwordConfirm.length === 0

  const navigate = useNavigate()

  const authContext = useContext(AuthContext)

  const sendClicked = async () => {
    try {
      await authContext.verifyCode(email, code)
      navigate('/signin' + location.search)
    } catch (err) {
      setError('Invalid Code')
    }
  }

  const signInClicked = async () => {
    try {
      await authContext.signUpWithEmail(email, email, password)
      setCreated(true)
      setError('')
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message)
      }
    }
  }

  const signUp = (
    <>
      <Grid container alignItems="center" justifyContent="center">
        <Stack direction='row' width='80%'>
          <Box width="80%" m={1}>
            <Box width="80%" m={1}>
              <Email emailIsValid={emailIsValid} setEmail={setEmail} />
            </Box>
            {/* <Box width="80%" m={1}>
          <Username usernameIsValid={usernameIsValid} setUsername={setUsername} />
        </Box> */}
            <Box width="80%" m={1}>
              <Password label="Password" passwordIsValid={passwordIsValid} setPassword={setPassword} />
            </Box>
            <Box width="80%" m={1}>
              <Password label="Confirm Password" passwordIsValid={passwordConfirmIsValid} setPassword={setPasswordConfirm} />
            </Box>
            <Box mt={2}>
              <Typography color="white" variant="body2">
                {error}
              </Typography>
            </Box>


            {/* PASSWORDS DONT MATCH */}
            { (password != passwordConfirm)  && (password.length != 0) ? 
              <Box mt={2}>
                <Typography color="white" variant="body2">
                  Error: Passwords must match
                </Typography>
              </Box>
              :
              <></>
            }


            {/* Buttons */}
            <Box mt={2}>
              <Grid container direction="row" >
                <Box m={1}>
                  <Button sx={{ color: 'white' }} onClick={() => navigate(-1)} color="orange" variant="contained">
                    Cancel
                  </Button>
                </Box>
                <Box m={1}>
                  <Button sx={{ color: 'white' }} disabled={isValid} color="orange" variant="contained" onClick={signInClicked}>
                    Sign Up
                  </Button>
                </Box>
              </Grid>
            </Box>
          </Box>
          <Typography sx={{ color: 'white' }}><b>Password Requirements:</b>
            <ol>
              <li>Minimum 1 uppercase letter</li>
              <li>Minimum 1 special character (symbol)</li>
              <li>Must be 8 or more characters long</li>
            </ol>
          </Typography>
        </Stack>
      </Grid>
    </>
  )

  const accountCreated = (
    <>
      <Grid container alignItems="center"
        justifyContent="center">
        <Stack alignItems={'center'}>
          {/* <Typography variant="h5" sx={{ color: 'white', pb: 1 }}>{`Account created for:  ${username}`}</Typography> */}
          <Typography variant="h5" sx={{ color: 'white', pb: 2 }}>{`Verification code has been sent to:  ${email}`}</Typography>


          {/* Sign In Form */}
          {/* <Box width="80%" m={1}> */}
          {/* <Email emailIsValid={emailIsValid} setEmail={setEmail} /> */}
          {/* <Username usernameIsValid={usernameIsValid} setUsername={setUsername} />{' '}
          </Box> */}
          <Box width="80%" m={1}>
            <Code codeIsValid={codeIsValid} setCode={setCode} />
            <Grid container direction="row" >
              <Box mt={2}>
                {/* <Typography variant="body2" color="white">
                  Resend Code
                </Typography> */}
                <Box mt={2}>
                  <Typography color="white" variant="body2">
                    {error}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Box>

          {/* Buttons */}
          <Stack direction="row" spacing={2}>
            <Button sx={{ color: 'white' }} color="orange" variant="contained" onClick={() => navigate(-1)}>
              Cancel
            </Button>
            <Button sx={{ color: 'white' }} disabled={isValid} color="orange" variant="contained" onClick={sendClicked}>
              Verify
            </Button>
          </Stack>


          {/* <Box m={4}>
            <Button onClick={() => navigate('/verify')} color="orange" variant="contained">
              Send Code
            </Button>
          </Box> */}

        </Stack>
      </Grid>
    </>
  )

  return (
    <ThemeProvider theme={theme}>
      {/* BACKGROUND DIV */}
      <div className="card" style={{
        background: "linear-gradient(90deg, #286A8A, #76EA89)", backgroundRepeat: 'no-repeat',
        backgroundSize: "cover", backgroundPosition: "center",
        width: '100vw', height: '100vh'
      }}>
        <Container maxWidth="lg" sx={{ pt: 8 }}>

          {/* Title */}
          <Typography
            variant="h2"
            align="center"
            gutterBottom
            color="white"
            sx={{ pb: 2, fontWeight: 'medium' }}>
            AWS Builder's Quest
          </Typography>
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            color="white"
            sx={{ pb: 1, fontWeight: 'medium' }}>
            Create New Account
          </Typography>

          {!created ? signUp : accountCreated}

        </Container>

      </div>
    </ThemeProvider>
  )
}
