import React, { useContext } from 'react'

import { useNavigate, Link, useParams } from 'react-router-dom'

import '../App.css'

import {
    ThemeProvider,
    createTheme,
    PaletteColorOptions,
    PaletteColor
} from "@mui/material/styles";

import {

    Typography,
    Stack,
}
    from '@mui/material'

declare module "@mui/material/styles" {
    // allow configuration using `createTheme`
    interface PaletteOptions {
        orange?: PaletteColorOptions;
    }

    interface Palette {
        orange: PaletteColor;
    }

    interface ButtonPropsColorOverrides {
        orange: true;
    }
}

declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides {
        orange: true;
    }
}

const theme = createTheme({
    palette: {
        orange: {
            main: "##FEAB34"
        }
    }
});

export default function signedOut() {

    const theme = createTheme({
        palette: {
            orange: {
                main: '#EAA628'
            }
        }
    });

    return (
        <ThemeProvider theme={theme}>
            {/* BACKGROUND DIV */}
            < div className="card" style={{
                background: "linear-gradient(90deg, #286A8A, #76EA89)", backgroundRepeat: 'no-repeat',
                backgroundSize: "cover", backgroundPosition: "center",
                width: '100vw', height: '100vh'
            }
            }>

                <Typography sx={{ pt: 10, pb: 2 }} color='white' align='center' variant="h5">You have been successfully signed out</Typography>
                <Typography sx={{ pt: 4 }} color='white' align='center' variant="h6">
                    Thank you for participating in AWS Builder's Quest. You may now close this window. 
                </Typography>
            </div>
        </ThemeProvider>
    );
}

