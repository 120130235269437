import { useState, useContext } from 'react'
import '../../App.css'

import { useNavigate, useLocation, useParams, Link, URLSearchParamsInit } from 'react-router-dom'

import {
  ThemeProvider,
  createTheme,
  PaletteColorOptions,
  PaletteColor
} from "@mui/material/styles";

import {
  Box,
  Grid,
  Button,
  Typography,
  Paper,
  Stack,
  Container,
  CircularProgress

}
  from '@mui/material'

import { useValidPassword, useValidUsername } from '../../hooks/useAuthHooks'
import { Password, Username } from '../../components/authComponents'

import { AuthContext } from '../../contexts/authContext'


declare module "@mui/material/styles" {
  // allow configuration using `createTheme`
  interface PaletteOptions {
    orange?: PaletteColorOptions;
  }

  interface Palette {
    orange: PaletteColor;
  }

  interface ButtonPropsColorOverrides {
    orange: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    orange: true;
  }
}

const theme = createTheme({
  palette: {
    orange: {
      main: "##FEAB34"
    }
  }
});

export default function SignIn() {

  const theme = createTheme({
    palette: {
      orange: {
        main: '#EAA628'
      }
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            input: {
              "&:-webkit-autofill": {
                WebkitBoxShadow: "0 0 0 100px #3282ab inset",
                WebkitTextFillColor: "white",
              },
            },
          },
        },
      },
    },
  });

  // const { eventId } = useParams();
  const location = useLocation();
  //const queryParameters = new URLSearchParams(window.location.search)
  const eventId = location.search;

  const { username, setUsername, usernameIsValid } = useValidUsername('')
  const { password, setPassword, passwordIsValid } = useValidPassword('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false);


  const isValid = !usernameIsValid || username.length === 0 || !passwordIsValid || password.length === 0

  const navigate = useNavigate();

  const authContext = useContext(AuthContext);

  const signInClicked = async () => {
    try {
        await authContext.signInWithEmail(username, password)
        navigate('../badge' + location.search, { state: SignIn })
    } catch (err: any) {
        if (err.code === 'UserNotConfirmedException') {
            navigate('../verify' + location.search)
        } else {
            setError(err.message)
        }
    }
}


  if (loading) return (
    <>
      <ThemeProvider theme={theme}>
        {/* BACKGROUND DIV */}
        <Box sx={{ display: 'flex' }} className="card" style={{
          background: "linear-gradient(90deg, #286A8A, #76EA89)", backgroundRepeat: 'no-repeat',
          backgroundSize: "cover", backgroundPosition: "center", overflow: 'auto',
          width: '100vw', height: '100vh'
        }}>
          <Grid container maxWidth="lg" sx={{ pt: 8 }} alignItems='center' justifyContent='center'>
            <Typography><CircularProgress style={{color: 'orange'}}/></Typography>
          </Grid>
        </Box>
      </ThemeProvider>
    </>
  )
  return (
    <ThemeProvider theme={theme}>

      {/* BACKGROUND DIV */}
      <Box sx={{ display: 'flex' }} className="card" style={{
        background: "linear-gradient(90deg, #286A8A, #76EA89)", backgroundRepeat: 'no-repeat',
        backgroundSize: "cover", backgroundPosition: "center", overflow: 'auto',
        width: '100vw', height: '100vh'
      }}>
        <Container maxWidth="lg" sx={{ pt: 8 }}>

          {/* HEADER */}
          <Typography
            variant="h2"
            align="center"
            gutterBottom
            color="white"
            sx={{ pb: 8, fontWeight: 'medium' }}>
            Welcome to AWS Builder's Quest
          </Typography>


          {/* SPLIT THE PAGE INTO TWO SIDES */}
          <Grid container spacing={2}>

            {/* LEFT SIDE OF LANDING PAGE FOR SIGN IN*/}
            <Grid item xs={6}>

              <Stack alignItems='center' spacing={2}>
                <Typography
                  variant="h4"
                  align="center"
                  gutterBottom
                  color="white"
                  sx={{ fontWeight: 'medium' }}>
                  Sign In
                </Typography>

                {/* Sign In Form */}
                <Box width="80%" m={1}>
                  {/* <Email emailIsValid={emailIsValid} setEmail={setEmail} /> */}
                  <Username usernameIsValid={usernameIsValid} setUsername={setUsername} />{' '}
                </Box>
                <Box width="80%" m={1}>
                  <Password label="Password" passwordIsValid={passwordIsValid} setPassword={setPassword} />
                  <Grid container direction="row">
                    <Box mt={2}>
                      <Typography variant="body2">
                        <Link style={{ color: 'orange' }} to={`/requestcode/${location.search}`}>Forgot Password</Link>
                      </Typography>
                    </Box>
                  </Grid>
                </Box>

                {/* Error */}
                <Box mt={2}>
                  <Typography color="white" variant="body2">
                    {error}
                  </Typography>
                </Box>

                {/* Buttons */}
                <Box mt={2}>
                  <Grid container direction="row" >
                    <Box m={1}>
                      <Button sx={{ color: 'white' }} color="orange" variant="contained" onClick={() => navigate(-1)}>
                        Cancel
                      </Button>
                    </Box>
                    <Box m={1}>
                      <Button sx={{ color: 'white' }} disabled={isValid} color="orange" variant="contained" onClick={signInClicked}>
                        Sign In
                      </Button>
                    </Box>
                  </Grid>
                </Box>
                <Typography
                  variant="h5"
                  align="center"
                  gutterBottom
                  color="white"
                  sx={{ pt: 5, fontWeight: 'medium' }}>
                  First Time Here?
                </Typography>
                <Button variant="contained" color="orange">
                  <Link style={{ textDecoration: 'none', color: 'white' }} to={`/signup/${location.search}`}>Create New Account</Link>
                </Button>
              </Stack>
            </Grid>

            {/* RIGHT SIDE OF LANDING PAGE WITH PICTURES */}
            <Grid item xs={6}>
              <Box sx={{ pt: 6, flexGrow: 1 }}>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                  {Array.from(Array(6)).map((_, index) => (
                    <Grid item xs={2} sm={4} md={4} key={index}>
                      <Box
                        component="img"
                        sx={{
                          height: 200,
                          width: 200,
                          maxHeight: { xs: 200, md: 200 },
                          maxWidth: { xs: 200, md: 200 },
                        }}
                        alt="Security and Compliance Badge"
                        src={require("../../assets/Badge" + index + ".png")}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
            {/* END RIGHT SIDE */}

          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
