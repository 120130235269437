import { useState, useContext } from 'react'
import '../../App.css'

import { useNavigate, useLocation, useParams } from 'react-router-dom'

import {
  Box,
  Grid,
  Button,
  Typography,
  Paper,
  Container,
  Stack
}
  from '@mui/material'

import {
  ThemeProvider,
  createTheme,
  PaletteColorOptions,
  PaletteColor
} from "@mui/material/styles";

import { useValidUsername } from '../../hooks/useAuthHooks'
import { Username } from '../../components/authComponents'

import { AuthContext } from '../../contexts/authContext'

declare module "@mui/material/styles" {
  // allow configuration using `createTheme`
  interface PaletteOptions {
    orange?: PaletteColorOptions;
  }

  interface Palette {
    orange: PaletteColor;
  }

  interface ButtonPropsColorOverrides {
    orange: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    orange: true;
  }
}

const theme = createTheme({
  palette: {
    orange: {
      main: "#FEAB34"
    }
  }
});


export default function RequestCode() {

  const theme = createTheme({
    palette: {
      orange: {
        main: '#EAA628'
      }
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            input: {
              "&:-webkit-autofill": {
                WebkitBoxShadow: "0 0 0 100px #3282ab inset",
                WebkitTextFillColor: "white",
              },
            },
          },
        },
      },
    },
  });

  // const { eventId } = useParams();
  const queryParameters = new URLSearchParams(window.location.search);
  const eventId = queryParameters.get("Event");

  const location = useLocation();

  const { username, setUsername, usernameIsValid } = useValidUsername('')
  const [error, setError] = useState('')
  const [resetSent, setResetSent] = useState(false)

  const isValid = !usernameIsValid || username.length === 0

  const navigate = useNavigate()

  const authContext = useContext(AuthContext)

  const sendCodeClicked = async () => {
    try {
      await authContext.sendCode(username)
      setResetSent(true)
    } catch (err) {
      setError('Unknown user')
    }
  }

  const emailSent = (
    <>
      <Box mt={1}>
        <Typography color='white' variant="h5">{`Reset Code Sent to ${username}`}</Typography>
      </Box>
      <Box mt={4}>
        <Button onClick={() => navigate('/forgotpassword' + location.search)} sx={{color: 'white'}} color="orange" variant="contained">
          Enter Code
        </Button>
      </Box>
    </>
  )

  const sendCode = (
    <>
      <Box width="40%" m={1}>
        <Username usernameIsValid={usernameIsValid} setUsername={setUsername} />
      </Box>
      <Box mt={2}>
        <Typography color="white" variant="body2">
          {error}
        </Typography>
      </Box>

      <Box mt={2}>
        <Grid container direction="row" >
          <Box m={1}>
            <Button sx={{color: 'white'}} color="orange" variant="contained" onClick={() => navigate(-1)}>
              Cancel
            </Button>
          </Box>
          <Box m={1}>
            <Button disabled={isValid} sx={{color: 'white'}}  color="orange" variant="contained" onClick={sendCodeClicked}>
              Send Code
            </Button>
          </Box>
        </Grid>
      </Box>
    </>
  )

  return (
    <ThemeProvider theme={theme}>
      {/* BACKGROUND DIV */}
      <div className="card" style={{
        background: "linear-gradient(90deg, #286A8A, #76EA89)", backgroundRepeat: 'no-repeat',
        backgroundSize: "cover", backgroundPosition: "center",
        width: '100vw', height: '100vh'
      }}>
        <Stack alignItems='center' justifyContent='center'>

          <Box m={2}>
            <Typography variant="h3" color='white'>Send Reset Code</Typography>
          </Box>
          {resetSent ? emailSent : sendCode}
        </Stack>
      </div>
    </ThemeProvider>
  )
}
