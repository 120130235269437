import { useState, useContext } from 'react'
import '../../App.css'

import { useNavigate, useLocation, useParams } from 'react-router-dom'

import {
  ThemeProvider,
  createTheme,
  PaletteColorOptions,
  PaletteColor
} from "@mui/material/styles";

import {
  Box,
  Grid,
  Button,
  Typography,
  Paper,
  Stack
}
  from '@mui/material'

import { useValidPassword, useValidUsername, useValidCode } from '../../hooks/useAuthHooks'
import { Password, Username, Code } from '../../components/authComponents'

import { AuthContext } from '../../contexts/authContext'

declare module "@mui/material/styles" {
  // allow configuration using `createTheme`
  interface PaletteOptions {
    orange?: PaletteColorOptions;
  }

  interface Palette {
    orange: PaletteColor;
  }

  interface ButtonPropsColorOverrides {
    orange: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    orange: true;
  }
}

const theme = createTheme({
  palette: {
    orange: {
      main: "#FEAB34"
    }
  }
});

export default function ForgotPassword() {

  const theme = createTheme({
    palette: {
      orange: {
        main: '#EAA628'
      }
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            input: {
              "&:-webkit-autofill": {
                WebkitBoxShadow: "0 0 0 100px #3282ab inset",
                WebkitTextFillColor: "white",
              },
            },
          },
        },
      },
    },
  });

  // const { eventId } = useParams();
  const queryParameters = new URLSearchParams(window.location.search)
  const eventId = queryParameters.get("Event")

  const location = useLocation();

  const { code, setCode, codeIsValid } = useValidCode('')
  const { password, setPassword, passwordIsValid } = useValidPassword('')
  const { username, setUsername, usernameIsValid } = useValidUsername('')
  const [error, setError] = useState('')
  const [reset, setReset] = useState(false)

  const {
    password: passwordConfirm,
    setPassword: setPasswordConfirm,
    passwordIsValid: passwordConfirmIsValid,
  } = useValidPassword('')

  const isValid =
    !codeIsValid ||
    code.length === 0 ||
    !usernameIsValid ||
    username.length === 0 ||
    !passwordIsValid ||
    password.length === 0 ||
    !passwordConfirmIsValid ||
    passwordConfirm.length === 0

  const navigate = useNavigate()

  const authContext = useContext(AuthContext)

  const resetPassword = async () => {
    try {
      await authContext.forgotPassword(username, code, password)
      setReset(true)
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message)
      }
    }
  }

  const updatePassword = (
    <>
      <Box width="80%" m={1}>
        <Code codeIsValid={codeIsValid} setCode={setCode} />
      </Box>
      <Box width="80%" m={1}>
        <Username usernameIsValid={usernameIsValid} setUsername={setUsername} />
      </Box>
      <Box width="80%" m={1}>
        <Password label="Password" passwordIsValid={passwordIsValid} setPassword={setPassword} />
      </Box>
      <Box width="80%" m={1}>
        <Password label="Confirm Password" passwordIsValid={passwordConfirmIsValid} setPassword={setPasswordConfirm} />
      </Box>

      {/* Error */}
      <Box mt={2}>
        <Typography color="white" variant="body2">
          {error}
        </Typography>
      </Box>

      {/* Buttons */}
      <Box mt={2}>
        <Grid container direction="row">
          <Box m={1}>
            <Button onClick={() => navigate(-1)} color="orange" variant="contained" sx={{color: 'white'}}>
              Cancel
            </Button>
          </Box>
          <Box m={1}>
            <Button disabled={isValid} color="orange" variant="contained" onClick={resetPassword} sx={{color: 'white'}}>
              Change Password
            </Button>
          </Box>
        </Grid>
      </Box>
    </>
  )

  const passwordReset = (
    <>
      <Typography variant="h5" color='white'>{`Password Reset`}</Typography>

      <Box m={4}>
        <Button onClick={() => navigate('/signin' + location.search)} color="orange" variant="contained" sx={{color: 'white'}}>
          Sign In
        </Button>
      </Box>
    </>
  )

  return (
    <ThemeProvider theme={theme}>
      {/* BACKGROUND DIV */}
      <div className="card" style={{
        background: "linear-gradient(90deg, #286A8A, #76EA89)", backgroundRepeat: 'no-repeat',
        backgroundSize: "cover", backgroundPosition: "center",
        width: '100vw', height: '100vh'
      }}>
        <Stack alignItems='center' justifyContent='center'>

          {/* Title */}
          <Box m={3}>
            <Grid container direction="row">
              <Typography variant="h3" color='white'>Forgot Password</Typography>
            </Grid>
          </Box>

          {!reset ? updatePassword : passwordReset}

        </Stack>
      </div>
    </ThemeProvider>
  )
}
