import React, { useContext, useState, useEffect } from 'react'

import { useNavigate, useSearchParams, Link } from 'react-router-dom'

import { Grid, Container, Paper, Typography, Checkbox, Box, TextField, Button, FormControlLabel, FormGroup } from '@mui/material'

import { AuthContext } from '../contexts/authContext'

import {
    ThemeProvider,
    createTheme,
    PaletteColorOptions,
    PaletteColor
} from "@mui/material/styles";

declare module "@mui/material/styles" {
    // allow configuration using `createTheme`
    interface PaletteOptions {
        orange?: PaletteColorOptions;
    }

    interface Palette {
        orange: PaletteColor;
    }

    interface ButtonPropsColorOverrides {
        orange: true;
    }
}

declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides {
        orange: true;
    }
}

const theme = createTheme({
    palette: {
        orange: {
            main: '#EAA628'
        }
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    input: {
                        "&:-webkit-autofill": {
                            WebkitBoxShadow: "0 0 0 100px #3282ab inset",
                            WebkitTextFillColor: "white",
                        },
                    },
                },
            },
        },
    },
});

type Event = { EventName: string; EventDate: string }[];

export default function Badge() {
    let [searchParams, setSearchParams] = useSearchParams()

    // const query = new URLSearchParams(window.location.search);
    const eventId = searchParams.get("Event")
    // const eventId = query.get("Event")

    const api = 'https://' + process.env.REACT_APP_API + '/event/badge';

    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [error, setError] = useState('')

    const navigate = useNavigate()

    const auth = useContext(AuthContext)
    const idToken = auth.sessionInfo?.idToken;
    console.log(idToken)
    const [event, setEvent] = useState<Event>([]);

    useEffect(() => {
        const api = 'https://' + process.env.REACT_APP_API + '/event/' + eventId;
        const dataFetch = async () => {
            try {
                const res = await fetch(api, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${idToken}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                });
                if (!res.ok) {
                    setError(`An error has occured: ${res.status} - ${res.statusText}`)
                }
                const data = await res.json();
                setError('');
                setEvent(data['Event']);
            } catch (err) {
                if (err instanceof Error) {
                    setError(err.message)
                }
            }
        };
        dataFetch();
    }, [event, setEvent]);

    const submit = async () => {
        console.log('submitting')
        try {
            const res = await fetch(api, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${idToken}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "FirstName": firstName,
                    "MiddleName": middleName,
                    "LastName": lastName,
                    "EmailAddress": email,
                    "EventHash": eventId,
                }),
            });
            if (res.status === 200) {
                //auth.signOut();
                console.log('success')
                navigate("/thankyou");
                //console.log('SUBMITTED')
            } else {
                setError(res.status + ' ' + res.statusText);
            }
        } catch (err) {
            if (err instanceof Error) {
                setError(err.message)
            }
        }
    };

    return (

        <ThemeProvider theme={theme}>
            {/* BACKGROUND DIV */}
            <div className="card" style={{
                background: "linear-gradient(90deg, #286A8A, #76EA89)", backgroundRepeat: 'no-repeat',
                backgroundSize: "cover", backgroundPosition: "center", overflow: 'auto',
                width: '100vw', height: '100vh'
            }}>

                <Typography sx={{ pt: 4, pb: 2 }} align='center' color='white' variant="h3">AWS Builders Quest</Typography>

                <Typography sx={{ pb: 2 }} align='center' color='white' variant="h4">Badge Request</Typography>
                <Box alignItems='center' justifyContent='center' m={2}>
                    <Grid container alignItems='center' justifyContent='center'>
                        {event != null ?
                            <Typography variant="h6" style={{ color: 'white' }}>
                                {
                                    event.map(val =>
                                        <p key="{val.EventName}">{val.EventName} attended on {val.EventDate}</p>)
                                }
                            </Typography>
                            :
                            <Typography></Typography>
                        }
                    </Grid>
                </Box>
                <Grid container alignItems='center' justifyContent='center'>
                    <Container maxWidth='md'>
                        <form>
                            <TextField
                                style={{ width: "97%", margin: "5px" }}
                                type="text"
                                label="First Name"
                                variant="outlined"
                                id='firstName'
                                sx={{ input: { color: 'white' } }}
                                InputLabelProps={{
                                    style: { color: '#fff' },
                                }}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                            <TextField
                                style={{ width: "97%", margin: "5px" }}
                                type="text"
                                label="Middle Name"
                                variant="outlined"
                                id='middleName'
                                sx={{ input: { color: 'white' } }}
                                InputLabelProps={{
                                    style: { color: '#fff' },
                                }}
                                onChange={(e) => setMiddleName(e.target.value)}
                            />
                            <TextField
                                style={{ width: "97%", margin: "5px" }}
                                type="text"
                                label="Last Name"
                                variant="outlined"
                                id='lastName'
                                sx={{ input: { color: 'white' } }}
                                InputLabelProps={{
                                    style: { color: '#fff' },
                                }}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                            <TextField
                                style={{ width: "97%", margin: "5px" }}
                                type="text"
                                label="Email"
                                variant="outlined"
                                id='email'
                                sx={{ input: { color: 'white' } }}
                                InputLabelProps={{
                                    style: { color: '#fff' },
                                }}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <Grid container direction="row" alignItems='center' justifyContent='center'>
                                <FormGroup>
                                    <FormControlLabel sx={{ color: 'white', pt: 1 }} control={<Checkbox defaultChecked sx={{ backgroundColor: 'orange', color: 'orange' }} />} label="By checking this box, 
                                I give Amazon Web Services (AWS) permission to share my name 
                                and email address with Credly for the purpose of obtaining an AWS Builder's Quest Learning Participant Badge from Credly.
                                My information will not be used for any other purposes." />
                                </FormGroup>
                            </Grid>

                            {/* Error */}
                            <Typography color="white" variant="body2">
                                {error}
                            </Typography>

                            {/* Buttons */}
                            <Grid container direction="row" alignItems='center' justifyContent='center' sx={{pt: 1}}>
                                <Box m={1}>
                                    <Button sx={{ color: 'white' }} color="orange" variant="contained" onClick={() => navigate('/')}>
                                        Cancel
                                    </Button>
                                </Box>
                                <Box m={1}>
                                    {/* <Link to='/' style={{textDecoration: 'none'}}> */}
                                    <Button sx={{ color: 'white' }} onClick={submit} variant="contained" color="orange">
                                        Submit
                                    </Button>
                                    {/* </Link> */}
                                </Box>
                            </Grid>
                        </form>
                    </Container>
                </Grid>
            </div>
        </ThemeProvider>
    )
}