import React, { useState, useContext } from 'react'

import { useNavigate } from 'react-router-dom'

import { 
  Box,
  Grid,
  Button,
  Typography,
  Paper }
  from '@mui/material'

import { useValidCode, useValidUsername } from '../../hooks/useAuthHooks'
import { Code, Username } from '../../components/authComponents'

import { AuthContext } from '../../contexts/authContext'

// const useStyles = makeStyles({
//   root: {
//     height: '100vh',
//   },
//   hover: {
//     '&:hover': { cursor: 'pointer' },
//   },
// })

export default function VerifyCode() {
  // const classes = useStyles()

  const { username, setUsername, usernameIsValid } = useValidUsername('')
  const { code, setCode, codeIsValid } = useValidCode('')
  const [error, setError] = useState('')

  const isValid = !usernameIsValid || username.length === 0 || !codeIsValid || code.length === 0

  const navigate = useNavigate()

  const authContext = useContext(AuthContext)

  const queryParameters = new URLSearchParams(window.location.search)
  const eventId = queryParameters.get("Event")

  const sendClicked = async () => {
    try {
      await authContext.verifyCode(username, code)
      navigate('/signin?Event=' + eventId)
    } catch (err) {
      setError('Invalid Code')
    }
  }

  const passwordResetClicked = async () => {
    navigate('/resetpassword?Event=' + eventId)
  }

  return (
    <Grid container direction="row" >
      <Grid xs={11} sm={6} lg={4} container direction="row"  item>
        <Paper style={{ width: '100%', padding: 32 }}>
          <Grid container direction="column">
            {/* Title */}
            <Box m={2}>
              <Typography variant="h3">Send Code</Typography>
            </Box>

            {/* Sign In Form */}
            <Box width="80%" m={1}>
              {/* <Email emailIsValid={emailIsValid} setEmail={setEmail} /> */}
              <Username usernameIsValid={usernameIsValid} setUsername={setUsername} />{' '}
            </Box>
            <Box width="80%" m={1}>
              <Code codeIsValid={codeIsValid} setCode={setCode} />
              <Grid container direction="row" >
                <Box onClick={passwordResetClicked} mt={2}>
                  <Typography  variant="body2">
                    Resend Code
                  </Typography>
                  <Box mt={2}>
                    <Typography color="white" variant="body2">
                      {error}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Box>

            {/* Buttons */}
            <Box mt={2}>
              <Grid container direction="row" >
                <Box m={1}>
                  <Button color="secondary" variant="contained" onClick={() => navigate(-1)}>
                    Cancel
                  </Button>
                </Box>
                <Box m={1}>
                  <Button disabled={isValid} color="primary" variant="contained" onClick={sendClicked}>
                    Send
                  </Button>
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}