import React, { useContext } from 'react'

import { useNavigate, useLocation } from 'react-router-dom'

import {
  ThemeProvider,
  createTheme,
  PaletteColorOptions,
  PaletteColor
} from "@mui/material/styles";

import {
  Box,
  Grid,
  Button,
  Typography,
  Link,
  Stack
}
  from '@mui/material'


import { AuthContext } from '../contexts/authContext'

declare module "@mui/material/styles" {
  // allow configuration using `createTheme`
  interface PaletteOptions {
    orange?: PaletteColorOptions;
  }

  interface Palette {
    orange: PaletteColor;
  }

  interface ButtonPropsColorOverrides {
    orange: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    orange: true;
  }
}

const theme = createTheme({
  palette: {
    orange: {
      main: "#EAA628"
    }
  }
});

export default function Home() {

  const location = useLocation();

  const navigate = useNavigate()

  const auth = useContext(AuthContext)

  function signOutClicked() {
    auth.signOut()
    navigate('/signedout')
  }

  function changePasswordClicked() {
    navigate('/changepassword' + location.search)
  }

  return (
    <ThemeProvider theme={theme}>
      {/* BACKGROUND DIV */}
      < div className="card" style={{
        background: "linear-gradient(90deg, #286A8A, #76EA89)", backgroundRepeat: 'no-repeat',
        backgroundSize: "cover", backgroundPosition: "center",
        width: '100vw', height: '100vh'
      }
      }>
        <Grid container alignItems='center' justifyContent='center'>
          <Stack alignContent='center' alignItems='center'>
            <Box m={2}>
              <Link style={{ textDecoration: 'none' }} underline="none" color="inherit" href="https:/aws.amazon.com">
                <Grid container direction="row" >
                  <Typography variant="h3" sx={{ color: 'white', pt: 5 }}>
                    AWS Builders Quest
                  </Typography>
                </Grid>
              </Link>
            </Box>
            <Stack direction='row' sx={{pt: 2}}>
              <Box m={2}>
                <Button onClick={signOutClicked} variant="contained" color="orange" style={{color: 'white'}}>
                  Sign Out
                </Button>
              </Box>
              <Box m={2}>
                <Button onClick={changePasswordClicked} variant="contained" color="orange" style={{color: 'white'}}>
                  Change Password
                </Button>
              </Box>
            </Stack>
          </Stack>
        </Grid>

      </div>
    </ThemeProvider>
  )
}
