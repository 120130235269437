import React, { useContext, useEffect, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import '../App.css'

import {
  ThemeProvider,
  createTheme,
  PaletteColorOptions,
  PaletteColor
} from "@mui/material/styles";

import {
  Box,
  Typography,
  Button,
}
  from '@mui/material'


declare module "@mui/material/styles" {
  // allow configuration using `createTheme`
  interface PaletteOptions {
    orange?: PaletteColorOptions;
  }

  interface Palette {
    orange: PaletteColor;
  }

  interface ButtonPropsColorOverrides {
    orange: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    orange: true;
  }
}

const theme = createTheme({
  palette: {
    orange: {
      main: "#EAA628"
    }
  }
});

export default function Landing() {

  const location = useLocation();
  const [error, setError] = useState(false);

  useEffect(() => {
    console.log(location.search)
    if (location.search === '') {
      setError(true);
    }
    else {
      setError(false);
    }
  }, []);

  if (error) return (
    <>
      <ThemeProvider theme={theme}>
        {/* BACKGROUND DIV */}
        < div className="card" style={{
          background: "linear-gradient(90deg, #286A8A, #76EA89)", backgroundRepeat: 'no-repeat',
          backgroundSize: "cover", backgroundPosition: "center",
          width: '100vw', height: '100vh'
        }
        }>

          <Typography sx={{ pt: 10, pb: 2 }} color='white' align='center' variant="h5">Error: This site requires an event ID</Typography>
          <Typography sx={{ pt: 4}} color='white' align='center' variant="h6">Please contact an event administrator</Typography>
        </div>
      </ThemeProvider>
    </>
  )
  return (
    <ThemeProvider theme={theme}>
      {/* BACKGROUND DIV */}
      < div className="card" style={{
        background: "linear-gradient(90deg, #286A8A, #76EA89)", backgroundRepeat: 'no-repeat',
        backgroundSize: "cover", backgroundPosition: "center",
        width: '100vw', height: '100vh'
      }
      }>

        <Typography sx={{ pt: 7, pb: 4 }} color='white' align='center' variant="h3">AWS Builders Quest</Typography>

        {/* Buttons */}
        <Box mt={2}
          display="flex"
          justifyContent="center"
          alignItems="top"
        >
          <Box m={1}>
            <Link style={{ textDecoration: 'none' }} to={`/signin${location.search}`}>
              <Button variant="contained" color="orange" sx={{ color: 'white' }}>
                Begin
              </Button>
            </Link>
          </Box>
        </Box>
      </div>
    </ThemeProvider>
  );
}

