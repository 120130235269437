import { Component } from 'react'
import React from 'react'

import './App.css'

import { HashRouter as Router, Route, Routes, useLocation } from 'react-router-dom'

import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  responsiveFontSizes
} from '@mui/material/styles';

import CssBaseline from '@mui/material/CssBaseline'

import AuthProvider, { AuthIsSignedIn, AuthIsNotSignedIn } from './contexts/authContext'

import VerifyCode from './routes/auth/verify'
import ChangePassword from './routes/auth/changePassword'
import ForgotPassword from './routes/auth/forgotPassword'
import SignUp from './routes/auth/signUp'
import SignIn from './routes/auth/signIn'
import Home from './routes/home'
import Landing from './routes/landing'
import SignedOut from './routes/signedOut'
import Badge from './routes/badge'
import ThankYou from './routes/thankyou'
import RequestCode from './routes/auth/requestCode'

import blue from '@mui/material/colors/blue';

let theme = createTheme({
  palette: {
    orange: {
      main: '#EAA628'
    }
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

theme = responsiveFontSizes(theme);

class App extends Component {
  render() {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AuthProvider>
            <AuthIsSignedIn>
              <Router>
                {/* <React.StrictMode> */}
                  <Routes>
                    <Route path="/changepassword" element={<ChangePassword />} />
                    <Route path="/badge" element={<Badge />} />
                    <Route path="/thankyou" element={<ThankYou />} />
                    <Route path="/" element={<Home />} />
                  </Routes>
                {/* </React.StrictMode> */}
              </Router>
            </AuthIsSignedIn>
            <AuthIsNotSignedIn>
              <Router>
                {/* <React.StrictMode> */}
                  <Routes>
                    <Route path="/signin" element={<SignIn />} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/signedout" element={<SignedOut />} />
                    <Route path="/verify" element={<VerifyCode />} />
                    <Route path="/requestcode" element={<RequestCode />} />
                    <Route path="/forgotpassword" element={<ForgotPassword />} />
                    <Route path="/thankyou" element={<ThankYou />} />
                    <Route path="/" element={<Landing />} />
                  </Routes>
                {/* </React.StrictMode> */}
              </Router>
            </AuthIsNotSignedIn>
          </AuthProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

export default App;